@font-face {
  font-family: 'Corbel';
  src: url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.eot");
  src: url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/29dc27977e417a98e56556776f41607c.svg#Corbel")format("svg");
}

@font-face {
  font-family: 'Corbel Italic';
  src: url('./Corbel\ Italic.ttf');
}

@font-face {
  font-family: 'Corbel Bold';
  src: url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.eot");
  src: url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c87f06ae40c78321844f2eac8b51737f.svg#Corbel Bold")format("svg");
}

@font-face {
  font-family: 'Corbel Bold Italic';
  src: url('./Corbel\ Bold\ Italic.ttf');
}
