.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 12px;
  height: 18px;
  text-align: center;
  margin: auto 4px;
  color: black;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 100px;
  line-height: 1.43;
  font-size: 14px;
  font-family: 'Corbel';
  font-weight: 400;
  min-width: 32px;

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  &.selected {
    background-color: #FFC000;
  }

  .arrow {
    &::before {
      position: relative;
      /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
      content: '';
      display: inline-block;
      width: 0.6em;
      height: 0.6em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    }

    &.left {
      padding-bottom: 4px;
      transform: rotate(227deg);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;

    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
}
